interface Props {
  title: string;
  subtitle: string;
}

export function SectionHeader(props: Props) {
  return (
    <header>
      <h1 class="text-2xl font-bold text-gray-900 dark:text-white">
        {props.title}
      </h1>
      <p class="text-gray-500 dark:text-gray-400 mt-1">{props.subtitle}</p>
    </header>
  );
}
